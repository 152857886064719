import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import ToolTip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { addFeatureRequest, allFeatureRequest } from "../../thunks/feature/thunks.js";
import { getAllApplicationsRequest } from "../../thunks/application/thunks.js";
import { getAllApplications } from "../../selectors/application/selector";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Chip from "@material-ui/core/Chip";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { getSelectedApplication } from "../../selectors/application/selector";
import { hasReadWriteRole } from '../../thunks/common/auth';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(2)
  },
  formControl: {
    minWidth: 240
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const AddFeature = ({
  onAddFeatureClick,
  applicationId,
  loadAllApplicationsFromServer,
  applicationType
}) => {
  const [open, setOpen] = React.useState(false);
  const [name, setNameValue] = React.useState("");
  const [scriptType, setScriptTypeValue] = React.useState("");
  
  const handleClickOpen = () => {
    console.log(loadAllApplicationsFromServer)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectChange = event => {
    setScriptTypeValue(event.target.value);
  };

  useEffect(() => {
    loadAllApplicationsFromServer();
  }, [loadAllApplicationsFromServer]);

  const classes = useStyles();

  var nameLength = name.length>2 && name.length<21;

  function SubmitButton(){
    if (nameLength && applicationType=="ALL"){
      return <Button
      onClick={() => {
        onAddFeatureClick(name, scriptType, applicationId);
        setNameValue("");
        handleClose();
      }}
      color="primary"
      variant="contained">Add Feature</Button>
    } else if (nameLength && applicationType!="ALL"){
      return <Button
      onClick={() => {
        onAddFeatureClick(name, applicationType, applicationId);
        setNameValue("");
        handleClose();
      }}
      color="primary"
      variant="contained">Add Feature</Button>
    }
    else {
      return <Button
      onClick={() => {
        onAddFeatureClick(name, scriptType, applicationId);
        setNameValue("");
        handleClose();
      }}
      color="primary"
      disabled
      variant="contained">Add Feature</Button>
    };
  };

  function ProjectType(){
    if (applicationType=="ALL" ){
      return (
      <FormControl className={classes.formControl}>
              <InputLabel id="script-select-label">
                Source Script Type
              </InputLabel>
              <Select
                labelId="source-script-type"
                required
                id="source-script-type"
                value={scriptType}
                onChange={handleSelectChange}
              >
                <MenuItem value={"KARATE"}>KARATE</MenuItem>
                <MenuItem value={"ANOROC"}>SELENIUM</MenuItem>
                <MenuItem value={"MOBILE"}>MOBILE</MenuItem>
                <MenuItem value={"PERFORMANCE"}>PERFORMANCE</MenuItem>
                <MenuItem value={"WINDOWS"}>WINDOWS</MenuItem>
                <MenuItem value={"BROWSER_USE"}>BROWSER_USE</MenuItem>         
              </Select>
            </FormControl>)
    } 
    else{
      return (
        (applicationType == 'ANOROC') ?  <Chip label="UI Testing" style={{backgroundColor: '#f50057', color: 'white'}}/> :  <Chip label={applicationType} style={{backgroundColor: '#f50057', color: 'white'}}/>
      )
    }
  };

  return (
    <Box>
      {hasReadWriteRole() && (
        <ToolTip title="Add Feature">
          <Fab
            color="primary"
            aria-label="add"
            className={classes.button}
            onClick={handleClickOpen}
          >
            <AddIcon />
          </Fab>
        </ToolTip>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Feature</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Box mb={2}>
            <TextField
              autoFocus
              autoComplete="off"
              margin="dense"
              required
              id="outlined-required"
              label="Feature Details"
              error={!nameLength}
              helperText={name !== "" ? "Feature name should be between 3-20 character" : " "}
              value={name}
              onChange={e => setNameValue(e.target.value)}
              fullWidth
            />
          </Box>
          <Box mb={2}>
            <ProjectType />
          </Box>
        </DialogContent>
        <DialogActions>
        <SubmitButton/>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = state => ({
  applications: getAllApplications(state),
  applicationId: getSelectedApplication(state).id,
  applicationType: getSelectedApplication(state).applicationType
});

const mapDispatchToProps = dispatch => ({
  onAddFeatureClick: (name, scriptType, applicationId) => {
    console.log("I am here")
    dispatch(allFeatureRequest(applicationId));
    dispatch(addFeatureRequest(name, scriptType, applicationId));
  },
  loadAllApplicationsFromServer: () => {
    dispatch(getAllApplicationsRequest());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFeature);
