import React, { useEffect } from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {listRunRequest} from "../../thunks/run/thunks";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SpeedIcon from '@material-ui/icons/Speed';
import FeatureTab from "./FeatureTab";
import TablePagination from "@material-ui/core/TablePagination";
import { getSelectedApplication } from "../../selectors/application/selector";
import { connect } from "react-redux";
import {
  listFeatureRequest,
  setSelectedFeatureRequest
} from "../../thunks/feature/thunks";
import AddFeature from "./AddFeature";
import { map } from "lodash";
import {
  getFeatures,
  getPage,
  getSize,
  getSearchFeatureCount
} from "../../selectors/feature/selector";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import DisplayDate from "../common/DisplayDate";
import { deepPurple, grey } from "@material-ui/core/colors";
import WebAssetIcon from "@material-ui/icons/Laptop";
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import SportsKabaddiIcon from "@material-ui/icons/SportsKabaddi";
import MobileScreenShareIcon from '@material-ui/icons/PhoneAndroid';
import Chip from "@material-ui/core/Chip";
import AlertDialog from './FeatureDialog'
import { hasReadWriteRole, hasAdminRole, hasProjectAdminRole } from '../../thunks/common/auth';
import FeatureSearch from "./FeatureSearchBar";
import { getAllEnvironmentsRequest } from "../../thunks/environment/thunks";
import {getAllGlobalObjectsRequest } from "../../thunks/globalObject/thunks";
import { LaptopWindows,Memory } from "@material-ui/icons";

const FeatureList = ({
  loadFeatureFromServer,
  loadRunsFromServer,
  loadGlobalObjectFromServer,
  loadEnvironmentsFromServer,
  selectedEnvironmentId,
  setSelectedFeature,
  applicationId,
  applicationName,
  features = [],
  count = -1,
  page = 0,
  size = 10
}) => {
  useEffect(() => {
    loadFeatureFromServer(0, size, applicationId);
    loadRunsFromServer(page, size, applicationId);
    loadGlobalObjectFromServer(applicationId);
    loadEnvironmentsFromServer(applicationId);
  }, [loadFeatureFromServer, size, applicationId],[loadRunsFromServer, page, size, applicationId], [loadGlobalObjectFromServer], [loadEnvironmentsFromServer]);

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [featureSelected, setFeatureSelected] = React.useState({});


  const handleListItemClick = index => {
    setSelectedIndex(index);
    setFeatureSelected(features[index]);
  };

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper
    },
    purple: {
      color: theme.palette.getContrastText(deepPurple[500]),
      backgroundColor: '#3f51b5'
    },
    brown: {
      color: theme.palette.getContrastText(grey[900]),
      backgroundColor: '#3f51b5'
    }
  }));

  const classes = useStyles();

  const onChangePage = (event, pageSelected) => {
    loadFeatureFromServer(pageSelected, size, applicationId);
    setSelectedIndex(0);
  };

  return (
    <div>
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Grid container>
          <Grid item xs={4} >
            <Box mt={1} mr={2}>
              <FeatureSearch mt={2} md={4} />
              <List className={classes.root} component="nav">
                {map(features, (feature, index) => (
                  <Grid container>
                    <Grid item xs={12} sm={12}>

                      <ListItem
                        divider
                        button
                        selected={selectedIndex === index}
                        onClick={event => {
                          handleListItemClick(index);
                          setSelectedFeature(features[index]);
                        }}
                      >
                        <ListItemAvatar>
                          {feature.featureType === "ANOROC" && (
                            <Avatar className={classes.purple}>
                              <ImportantDevicesIcon />
                            </Avatar>
                          )}
                          {feature.featureType === "KARATE" && (
                            <Avatar className={classes.brown}>
                              <SportsKabaddiIcon />
                            </Avatar>
                          )}
                          {feature.featureType === "PERFORMANCE" && (
                            <Avatar className={classes.brown}>
                              <SpeedIcon />
                            </Avatar>
                          )}
                          {feature.featureType === "MOBILE" && (
                            <Avatar className={classes.brown}>
                              <MobileScreenShareIcon />
                            </Avatar>
                          )}
                          {feature.featureType === "WINDOWS" && (
                            <Avatar className={classes.purple}>
                              <LaptopWindows />
                            </Avatar>
                          )}
                          {feature.featureType === "BROWSER_USE" && (
                            <Avatar className={classes.purple}>
                              <Memory />
                            </Avatar>
                          )}
                        </ListItemAvatar>
                        <ListItemText
                          primary={feature.name}
                          secondary={
                            <DisplayDate
                              dateString={feature.created_date_time}
                              prefixString="Created on "
                            />
                          }
                        />
                        <Grid item xs={12} sm={2} style={{ paddingRight: '140px' }}>
                          <Chip label={applicationName} style={{ backgroundColor: '#f50057', color: 'white' }} />
                        </Grid>

                        {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                          <Grid item xs={12} sm={1}>
                            <AlertDialog dialogLabel='feature' selectedFeatureId={featureSelected.id} featureName={featureSelected.name} />
                          </Grid>
                        )}
                      </ListItem>
                    </Grid>
                  </Grid>

                ))}
              </List>
              {count > 0 && (
                <Box display="flex" flexDirection="row-reverse">
                  <AddFeature />
                  <TablePagination
                    rowsPerPageOptions={[size]}
                    component="div"
                    count={count}
                    rowsPerPage={size}
                    page={page}
                    onChangePage={onChangePage}
                  />
                </Box>
              )}

              {count == 0 && (
                <Box display="flex" justifyContent="space-evenly" style={{ color: '#f50057', fontSize: "large" }}>
                  No Search Results found. Please clear search box and try again !!!
                </Box>
              )}

            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box mt={2} mr={2}>
              {count > 0 && <FeatureTab />}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <Grid container>
          <Grid item xs={12} >
            <Box mt={1} mr={2}>
              <FeatureSearch mt={2} md={2} />
              <List className={classes.root} component="nav">
                {map(features, (feature, index) => (
                  <Grid container>
                    <Grid item xs={12} sm={12}>

                      <ListItem
                        divider
                        button
                        selected={selectedIndex === index}
                        onClick={event => {
                          handleListItemClick(index);
                          setSelectedFeature(features[index]);
                        }}
                      >
                        <ListItemAvatar>
                          {feature.featureType === "ANOROC" && (
                            <Avatar className={classes.purple}>
                              <ImportantDevicesIcon />
                            </Avatar>
                          )}
                          {feature.featureType === "KARATE" && (
                            <Avatar className={classes.brown}>
                              <SportsKabaddiIcon />
                            </Avatar>
                          )}
                          {feature.featureType === "PERFORMANCE" && (
                            <Avatar className={classes.brown}>
                              <SpeedIcon />
                            </Avatar>
                          )}
                           {feature.featureType === "MOBILE" && (
                            <Avatar className={classes.brown}>
                              <MobileScreenShareIcon />
                            </Avatar>
                          )}
                          {feature.featureType === "WINDOWS" && (
                            <Avatar className={classes.purple}>
                              <LaptopWindows />
                            </Avatar>
                          )}
                          {feature.featureType === "BROWSER_USE" && (
                            <Avatar className={classes.purple}>
                              <Memory />
                            </Avatar>
                          )}
                          
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Box><Typography variant="body2" style={{ width: '400px' }}>{feature.name}</Typography></Box>}
                          secondary={<Typography variant="body2" color="textSecondary" style={{ width: '400px' }}> <DisplayDate
                            dateString={feature.created_date_time} />
                          </Typography>}
                        />
                        <Grid item xs={12} sm={2} style={{ paddingLeft: '200px', paddingRight: '20px' }}>
                          {/* <Chip label={applicationName} style={{ backgroundColor: '#f50057', color: 'white' }} /> */}
                        </Grid>

                        {hasReadWriteRole() && (hasAdminRole() || hasProjectAdminRole()) && (
                          <Grid item xs={12} sm={1}>
                            <AlertDialog dialogLabel='feature' selectedFeatureId={featureSelected.id} featureName={featureSelected.name} />
                          </Grid>
                        )}
                      </ListItem>
                    </Grid>
                  </Grid>

                ))}
              </List>
              {count > 0 && (
                <Box display="flex" flexDirection="row-reverse">
                  <AddFeature />
                  <TablePagination
                    rowsPerPageOptions={[size]}
                    component="div"
                    count={count}
                    rowsPerPage={size}
                    page={page}
                    onChangePage={onChangePage}
                  />
                </Box>
              )}

              {count == 0  && (
                <Box display="flex" justifyContent="space-evenly" style={{ color: '#f50057', fontSize: "large" }}>
                  No Search Results found. Please clear search box and try again !!!
                </Box>
              )}

            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box >
              {count > 0 && <FeatureTab />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

const mapStateToProps = state => ({
  features: getFeatures(state),
  count: getSearchFeatureCount(state),
  page: getPage(state),
  size: getSize(state),
  applicationId: getSelectedApplication(state).id,
  applicationName: getSelectedApplication(state).name
  //associatedFeatures: getAssociatedFeatures(state)
});

const mapDispatchToProps = dispatch => ({
  loadFeatureFromServer: (page, size, applicationId) => {
    dispatch(listFeatureRequest(page, size, "", applicationId));
  },
  setSelectedFeature: feature => {
    dispatch(setSelectedFeatureRequest(feature));
  }, 
  loadRunsFromServer: (page, size, applicationId) => {
    dispatch(listRunRequest(page, size, applicationId));
  },
  loadGlobalObjectFromServer: (applicationId) => {
    dispatch(getAllGlobalObjectsRequest(applicationId));
  },
  loadEnvironmentsFromServer: (applicationId) => {
    dispatch(getAllEnvironmentsRequest(applicationId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FeatureList);
